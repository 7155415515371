import {
  faFacebookF,
  faXTwitter,
  faWhatsapp,
  faRedditAlien,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyIcon, MailIcon, CheckIcon, Share2Icon } from "lucide-react";
import * as React from "react";

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";

function YourlsShareButton({ url }: { url: string }) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <React.StrictMode>
      <div className="font-sans">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline">
              <Share2Icon className="mr-2 h-4 w-4" />
              Share
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[25.25rem]">
            <DialogHeader>
              <DialogTitle>Share</DialogTitle>
            </DialogHeader>

            <ScrollArea className="whitespace-nowrap">
              <div className="flex gap-2">
                <ShareButton
                  label="WhatsApp"
                  href={`https://wa.me/?text=${url}`}
                >
                  <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6" />
                </ShareButton>
                <ShareButton
                  label="Facebook"
                  href={`https://www.facebook.com/sharer.php?u=${url}`}
                >
                  <FontAwesomeIcon icon={faFacebookF} className="h-6 w-6" />
                </ShareButton>
                <ShareButton
                  label="X/Twitter"
                  href={`https://x.com/intent/post?url=${url}`}
                >
                  <FontAwesomeIcon icon={faXTwitter} className="h-6 w-6" />
                </ShareButton>
                <ShareButton label="Email" href={`mailto:?body=${url}`}>
                  <MailIcon className="h-6 w-6" />
                </ShareButton>
                <ShareButton
                  label="Reddit"
                  href={`https://reddit.com/submit?url=${url}`}
                >
                  <FontAwesomeIcon icon={faRedditAlien} className="h-6 w-6" />
                </ShareButton>
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>

            <div className="flex items-center space-x-2">
              <div className="grid flex-1 gap-2">
                <Label htmlFor="link" className="sr-only">
                  Link
                </Label>
                <Input ref={inputRef} id="link" defaultValue={url} readOnly />
              </div>
              <CopyButton inputRef={inputRef} url={url} />
            </div>
          </DialogContent>
        </Dialog>
        <Toaster />
      </div>
    </React.StrictMode>
  );
}

function ShareButton({
  label,
  href,
  children,
}: {
  label: string;
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Button
      variant="ghost"
      size="icon-lg"
      className="flex-col gap-1.5 text-xs text-foreground"
      asChild
    >
      <a href={href} target="_blank" rel="noreferrer">
        {children}
        {label}
      </a>
    </Button>
  );
}

function CopyButton({
  inputRef,
  url,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  url: string;
}) {
  const { toast } = useToast();
  const [wasRecentlyCopied, setWasRecentlyCopied] = React.useState(false);

  async function copyUrlToClipboard() {
    try {
      if (!navigator.clipboard) {
        throw new Error("Clipboard not supported");
      }

      await navigator.clipboard.writeText(url);

      setWasRecentlyCopied(true);
      setTimeout(() => setWasRecentlyCopied(false), 2000);
      toast({
        title: "Copied",
        description: "Link copied to clipboard",
      });
      inputRef.current?.select();
    } catch {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to copy link to clipboard",
      });
    }
  }

  return (
    <Button
      type="submit"
      size="sm"
      className="!mt-0 !px-3 !py-0"
      onClick={copyUrlToClipboard}
    >
      <span className="sr-only">Copy</span>
      {wasRecentlyCopied ? (
        <CheckIcon className="h-4 w-4" />
      ) : (
        <CopyIcon className="h-4 w-4" />
      )}
    </Button>
  );
}

export default YourlsShareButton;
