import r2wc from "@r2wc/react-to-web-component";
import "./globals.css";

import YourlsShareButton from "./components/yourls-share-button";

customElements.define(
  "yourls-share-button",
  r2wc(YourlsShareButton, {
    props: {
      url: "string",
    },
  }),
);
